@import "styles/variables";
@import "~antd/dist/antd.css";

html,
body {
  @media (max-width: $mobile) {
    height: 100%;
    min-height: 100vh;
  }
}

body {
  font-family: "Poppins", sans-serif;
  // letter-spacing: 0.5px;
  background: fixed
    linear-gradient(120.21deg, #381792 -5.83%, #301b8b 24.68%, #111342 100%)
    no-repeat;
}
