@import "styles/variables.scss";

.title {
  font-size: 24px;
  line-height: 36px;
  color: #fff;
  font-weight: 600;

  margin-bottom: 0;
  margin-right: 15px;

  @media (max-width: $mobile) {
    margin-bottom: 10px;
  }
}

.tabs:global(.ant-tabs) {
  color: #fff;
}

.tabs:global(.ant-tabs) > :global(.ant-tabs-nav):before {
  border: none;
}

.tabs :global(.ant-tabs-tab) {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  padding-bottom: 6px;
}

.tabs :global(.ant-tabs-tab-active .ant-tabs-tab-btn),
.tabs :global(.ant-tabs-tab):hover {
  color: #00ffd1;
}

.tabs :global(.ant-tabs-ink-bar) {
  background-color: #00ffd1;
}

.popover:global(.ant-popover) {
  // animation-duration: 0s !important;

  :global(.ant-popover-content) {
    max-width: 350px;
    @media (max-width: $mobile) {
      margin-right: $gutter-mobile;
      margin-left: $gutter-mobile;
    }
  }

  :global(.ant-popover-arrow) {
    display: none;
  }

  :global(.ant-popover-inner) {
    background: transparent;
  }

  :global(.ant-popover-inner-content) {
    padding: 0;
  }
}

.popupWrapper {
  display: flex;
  background: #0c0d30;
  color: white;
  padding: 15px;
  border-radius: 16px;
  font-weight: 500;
  column-gap: 24px;
  font-size: 18px;
  line-height: 150%;

  &::before {
    content: "";
    position: absolute;
    top: 10px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -1px;
    border-radius: inherit;
    background: linear-gradient(
      132deg,
      #ffb800 14.21%,
      #00ffdd 51.62%,
      #7000ff 100%
    );

    @media (max-width: $mobile) {
      left: 20px;
      right: 20px;
    }
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.container {
  display: flex;
  margin-bottom: 34px;

  @media (max-width: $mobile) {
    flex-wrap: wrap;
  }
}
