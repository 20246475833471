.detailsText {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-width: 100px;
  color: #fff;
}

.label {
  display: flex;
  font-size: 16px;
  margin-bottom: 3px;
}

.text {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

.hint {
  margin-left: 7px;
  cursor: pointer;
}
