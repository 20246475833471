@import "styles/variables.scss";

.title {
  font-weight: 600;
  font-size: 24px;
  color: #fff;
  line-height: 36px;
}

.button:global(.ant-btn) {
  width: 100%;
  height: 52px;
}

.depositWrapper {
  color: #bdbdbd;
  font-size: 16px;
  font-weight: 600;
}

.amountWrapper {
  display: flex;
  justify-content: space-between;

  @media (max-width: $mobile) {
    flex-direction: column;
  }
}

.balance {
  font-weight: 600;
  color: #2f80ed;
}
