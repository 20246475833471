.countdownContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 26px;
  color: white;
}

.title {
  margin-bottom: 15px;
}

.timer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.number {
  height: 65px;
  width: 45px;
  background-color: rgb(12, 13, 48);
  color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
}

.w100 {
  width: 100%;
}
