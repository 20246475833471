@import "styles/variables.scss";

.card {
  background-color: #0c0d30;
  position: relative;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 16px;

  & :global(.ant-card-body) {
    padding: 0;
  }

  & :global(.ant-row) {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

.card:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -1px;
  border-radius: inherit;
  background: linear-gradient(
    132deg,
    #ffb800 14.21%,
    #00ffdd 51.62%,
    #7000ff 100%
  );
}

.row {
  width: 100%;
  padding: 20px;

  @media (max-width: $mobile) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.title {
  color: #fff;
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
}

.line {
  height: 1px;
  background: linear-gradient(
    132deg,
    #ffb800 14.21%,
    #00ffdd 51.62%,
    #7000ff 100%
  );
}
